import React from "react"
import { PageLayout } from "../components/Layout"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import TextSectionBackground from "../components/TextSectionBackground"
import { ActionButton } from "../components/buttons"
import { ButtonBasic } from "../components/buttons"
import EnquireButton from "../components/EnquireButton"
import { SectionHeader, SubSectionHeader } from "../components/Typography"
import styled from "styled-components"
import SEO from "../components/SEO"

const RatesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  >div {
    margin: 10px;
    @media (max-width: 520px) {
      width: 100%;
    }
  }
  h3 {
    text-align: left;
    color: #666;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    font-weight: 700;
    font-size: 28px;
    margin-top: 5rem;
  }
  h4 {
    font-size: 19px;
    line-height: 22px;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    color: #4c4c4c;
    max-width: 450px;
  }
  h5 {
    text-align: left;
    color: #666;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    font-weight: 700;
    font-size: 28px;
    &.center {
      text-align: center;
      margin-bottom: 0;
    }
  }
  p {
    text-align: left;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    color: #666;
    font-weight: 500;
    line-height: 1.7em;
    font-size: 20px;
    &.center {
      text-align: center;
      margin-bottom: 0;
    }
    &.bookings {
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      a {
        text-decoration: none;
        color: #919191;
      }
    }
  }
  .image-wrapper {
    @media all and (max-width: 520px) {
      width: 100% !important;
    }
  }
  .grow {
    border: none;
    background: none;
    position: relative;
    &:hover {
      picture {
        img {
          transform: scale(1.1);
          filter: saturate(1.3);
        }
      }
    }
    
    picture {
      img {
        width: 450px;
        cursor: pointer;
        transition: transform 0.5s, filter 0.25s !important;
        &:hover {
          transform: scale(1.1);
          filter: saturate(1.3);
        }
        @media all and (max-width: 520px) {
          width: 100%;
        }
      }
    }
    .backgroundText {
      transition: all 0.3s linear;
      cursor: pointer;
      position: absolute;
      background-color: rgba(79, 79, 79, 0);
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: white;
        line-height: 36px;
        font-size: 15px;
        text-align: center;
        position: relative;
        color: rgba(255, 255, 255, 0);
      }
      &:hover {
        background-color: rgba(79, 79, 79, 0.6);
        p {
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
`

const Intro = styled.div`
  max-width: 1200px;
  margin: 0 auto 3rem;
  font-family:"Playfair Display", Georgia, "Times New Roman", serif;
  font-size: 16px;
  text-align: left;
  color: #666;
`

const Thumbnail = styled.div`
  width: 450px;
  height: 300px;
  position: relative;
  @media all and (max-width: 520px) {
    width: 100%;
  }

`

const ButtonWrapper = styled.div`
  text-align: center;
  button {
    margin: 8px 10px;
  }

`

const Accommodation = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSpecial {
        nodes {
          header
          slug
          picture {
            fixed(width:450 height:300) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
        }
      }
    }
  `)
  return (
    <PageLayout>
      <SEO 
      title="Specials"
      description="Specials at Mavela Game Lodge"
      />
      <TextSectionBackground pa="4% 5%">
        <SectionHeader>Specials</SectionHeader>
        <SubSectionHeader>
          Contact us directly for the best deals
        </SubSectionHeader>
        <Intro>
          <p>
            We are currently undertaking major renovations to our accommodation 
            at the lodge. The renovation will see the quality of the accommodation 
            improve significantly. 
          </p>
          <p>
            The first stage of the renovation should be completed by the middle of 
            June if everything goes smoothly. This will see the first 4 tents 
            completed. The next stage will be building an extra 3 tents, and 
            should be completed by around October.
          </p>
        </Intro>
        <RatesWrapper>
          {data.allContentfulSpecial.nodes.map((special: any, i: number) => (
            <div key={special.header}>
              <h4>{special.header}</h4>
              <Link to={`/special/${special.slug}`} className="grow">
                <Thumbnail>
                <Img fixed={special.picture.fixed} className="image-wrapper"></Img>
                  <div className="backgroundText">
                    <p>
                      Contact us to find out more or
                      <br />
                      <span style={{ textDecoration: "underline" }}>
                        Read more...
                      </span>
                    </p>
                  </div>
                </Thumbnail>                
              </Link>
            </div>
          ))}
        </RatesWrapper>
      </TextSectionBackground>
      <TextSectionBackground pa="1rem 5% 4rem">
        <ButtonWrapper>
          <EnquireButton />
          <ActionButton>
            <a
              href="https://www.nightsbridge.co.za/bridge/book?bbid=16200"
              rel="noopener noreferrer"
              target="_blank"
            >
              Check Availability
            </a>
          </ActionButton>
          <ButtonBasic>
            <a
              href="/RackRates.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Download Rates Sheet
            </a>
          </ButtonBasic>
          </ButtonWrapper>
      </TextSectionBackground>
    </PageLayout>
  )
}

export default Accommodation
